import React  from 'react';
import { Container, Button} from 'reactstrap';
import scrollToElement from '../../helpers/scrollToElement';

const Faster2 = () =>(
    <section className="faster-2">
        <Container>
            <h2>Dieta i treningi<br/>prostsze i skuteczniejsze</h2>
            <p><strong>Jedzenie, które naprawdę lubisz.</strong> Dzięki setkom nowych, super prostych przepisów i zamienników Twój wybór jest nieograniczony. W aplikacji Diet &amp; Training by Ann znajdziesz 4 warianty diety (w tym wege), a w każdej z nich możliwość wymiany składników w posiłkach lub całych posiłków, a także opcję kopiowania dnia na kolejny, dzięki czemu zyskasz możliwość ugotowania obiadu raz na 2 dni.</p>
            <p><strong>Szybsze treningi i konkretne efekty.</strong> Nowe treningi 13, 15, 20 i 30 minutowe, oraz te dedykowane poszczególnym częściom ciała: <strong>wyrzeźbij swój brzuch</strong>, pośladki, nogi, ramiona i&nbsp;plecy.</p>
            <p><strong>Zdrowy styl życia?</strong> Tak, ale tylko na Twoich warunkach!</p>

            <Button
                    color="red"
                    className="mt-4"
                    onClick={() =>
                        scrollToElement(
                            document.getElementById('download')
                        )
                    }
                >
                POBIERZ APLIKACJĘ
            </Button>
        </Container>
    </section>
);

export default Faster2;
